import * as React from "react";

import SEO from "../components/seo";
import Grid from "../components/grid";
import TextBlock from "../components/text-block";
import ExitButton from "../components/exit-button";

// markup
const AboutPage = () => {
  return (
    <>
      <SEO title="About" />
      <ExitButton />
      <Grid>
        <TextBlock>
          <h1 className="hide-on-desktop">About</h1>
          <p>Surya Capital Partners (Surya CP) FKA Maverick Commercial Properties founded in 2011 is a leading Nationwide Real Estate Private Equity and Debt financing boutique advisory firm headquartered out of New York. Surya CP is renowned for its ability to customize bespoke financing solutions for its clients for all property types with a very targeted approach in a very timely manner. The Company capitalizes transactions all the way from stable cash flowing deals to complex and heavily structured transactions such as ground leases, note acquisitions and high leverage acquisition, conversion and construction financing. The key to success for the firm over its short lifespan has been its unique approach to the business - create bespoke financing solutions for its clients.</p>
          <p>The Surya CP team comprises professionals who come from an institutional background with over 60 years of collective experience at various large Investment Banks including Wachovia, Merrill Lynch, JP Morgan and Deutsche Bank. This experience allows its team to quickly evaluate and create financing structures and provide solutions from a very institutional point of view to its clients, yet providing a much more personalized service to its clients more in sync with a boutique firm. Surya CP and its team approaches this business with the simple underlying philosophy that “this is a financing business, not a brokerage business” and that has contributed to the significant success the firm has enjoyed.</p>
          <p>Surya Capital Partners (Surya CP) has diversified into principal investments as well and has created a JV with Arod Corp. Surya CP is a partner in a $500.0mm Hospitality Fund with CGI Merchant Group. In addition, Surya CP also makes Co-GP investments & creates strategic partnerships with developers, owners & operators nationwide.</p>
        </TextBlock>
      </Grid>
    </>
  )
};

export default AboutPage;
